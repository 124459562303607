<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6 pb-0">Course</v-card-title>
        <div class="pa-5">
          <v-form ref="form">
            <v-col cols="12" class="pa-0 mt-3">
              <v-text-field
                v-model="selectedCourse.description"
                label="Description"
                outlined
                :rules="[(v) => !!v || 'Description is required']"
                prepend-icon="mdi-text"
                required
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="selectedCourse.course_name_on_certificate"
                label="Course name on certificate"
                outlined
                prepend-icon="mdi-text"
                :rules="[
                  (v) => !!v || 'Course name on certificate is required',
                ]"
                required
              />
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                type="number"
                class="pr-1"
                v-model="selectedCourse.max_course_try"
                label="Max Course Try"
                prepend-icon="mdi-numeric"
                outlined
              />
              <v-text-field
                type="number"
                hide-details
                v-model="selectedCourse.passing_score"
                label="Passing score"
                append-icon="mdi-percent"
                outlined
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="selectedCourse.sign_up_title"
                prepend-icon="mdi-text"
                label="Signup title"
                outlined
              />
            </v-col>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Signup Info</div>

                <HtmlEditor
                  v-model="selectedCourse.sign_up_info"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>

            <v-col cols="12" class="pa-0">
              <v-text-field
                type="password"
                v-model="selectedCourse.sign_up_password"
                label="Signup password"
                outlined
                required
                prepend-icon="mdi-form-textbox-password"
                :rules="[(v) => !!v || 'Signup password is required']"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-textarea
                outlined
                required
                no-resize
                :rules="[(v) => !!v || 'Extra signup check is required']"
                label="Signup check"
                prepend-icon="mdi-text"
                v-model="selectedCourse.extra_sign_up_check"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                type="number"
                v-model="selectedCourse.max_video_answer_length"
                label="Max Video Answer Length"
                prepend-icon="mdi-numeric"
                outlined
              />
            </v-col>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Terms of Use</div>

                <HtmlEditor
                  v-model="selectedCourse.terms_of_use"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Privacy Policy</div>

                <HtmlEditor
                  v-model="selectedCourse.privacy_policy"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>

            <v-col cols="12" class="d-flex pa-0">
              <v-select
                outlined
                label="Language"
                class="pr-1"
                v-model="selectedCourse.language"
                :items="languages"
                item-title="text"
                item-value="value"
                required
                prepend-icon="mdi-format-list-bulleted-square"
                :rules="[(v) => !!v || 'Language is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                class="pr-1"
                v-model="selectedCourse.url"
                label="URL"
                prepend-icon="mdi-text"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                type="number"
                class="pr-1"
                v-model="selectedCourse.price_per_student"
                label="Price per student"
                prepend-icon="mdi-cash"
                prepend-inner-icon="mdi-currency-eur"
                outlined
              />
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                type="number"
                class="pr-1"
                v-model="selectedCourse.vat"
                label="Vat"
                prepend-icon="mdi-percent"
                outlined
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <image-upload
                class="pa-0"
                label="Sign Up Logo"
                v-model="selectedCourse.sign_up_logo"
                :rules="rules"
              ></image-upload>
            </v-col>
            <v-col cols="12" class="pa-0">
              <image-upload
                class="pa-0"
                label="Thumbnail"
                v-model="selectedCourse.thumbnail"
                :rules="rules"
              ></image-upload>
            </v-col>
            <v-col cols="12" class="pa-0">
              <image-upload
                class="pa-0"
                label="Background Image *"
                v-model="selectedCourse.background_image"
                :rules="rules"
              ></image-upload>
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-select
                outlined
                label="Certificate"
                v-model="selectedCourse.certificate_id"
                :items="getCertificates"
                prepend-icon="mdi-format-list-bulleted-square"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Certificate Email</div>

                <HtmlEditor
                  v-model="selectedCourse.certificate_email"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">
                  Payment Invoice Mail Body
                </div>

                <HtmlEditor
                  v-model="selectedCourse.payment_invoice_mail_body"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>

            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                class="pr-1"
                v-model="selectedCourse.certificate_email_bcc"
                label="Certificate Email BCC"
                prepend-icon="mdi-text"
                outlined
              />
            </v-col>
            <v-col md="12" class="d-flex pa-0">
              <v-select
                outlined
                label="Certificate Expire Year"
                v-model="selectedCourse.certificate_expire_year"
                prepend-icon="mdi-format-list-bulleted-square"
                :items="expireYears"
                item-title="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-select
                outlined
                label="Signup Notification"
                :items="notifications"
                item-text="label"
                item-value="value"
                prepend-icon="mdi-format-list-bulleted-square"
                v-model="selectedCourse.notification_id"
                :rules="[(v) => !!v || 'This is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                v-model="selectedCourse.sign_up_mail_subject"
                prepend-icon="mdi-text"
                label="Signup Mail Subject"
                outlined
              />
            </v-col>
            <v-col cols="12" class="d-flex pa-0">
              <v-text-field
                v-model="selectedCourse.export_email"
                prepend-icon="mdi-text"
                label="Export Email"
                outlined
              />
            </v-col>
            <v-row class="px-5">
              <v-col
                v-for="column in switches"
                :key="column.db"
                cols="12"
                md="6"
              >
                <v-switch
                  class="pa-0 ma-0"
                  inset
                  v-model="selectedCourse[column.db]"
                  :label="column.text"
                  :true-value="1"
                  :false-value="0"
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="color in colorPickers"
                :key="color.db"
                md="6"
                class="d-flex justify-center pa-0"
              >
                <div class="ma-1">
                  <p class="color-picker-label">
                    {{ color.text }}
                  </p>
                  <v-color-picker
                    :value="getColorModel(color.db)"
                    @input="setColor(color.db, $event)"
                    elevation="0"
                    mode="hexa"
                  ></v-color-picker>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-10" justify="end">
              <v-btn color="primary" @click="edit">Edit Course </v-btn>
            </v-row>
          </v-form>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import HtmlEditor from "@/components/HtmlEditor";
import ImageUpload from "@/components/ImageUpload.vue";
import { CERTIFICATE_EXPIRE_YEARS, LANGUAGES } from "@/shared/constants";
import { convertObjectToFormData } from "@/shared/utils";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ImageUpload, HtmlEditor },
  metaInfo: {
    title: "Courses",
  },
  data() {
    return {
      expireYears: CERTIFICATE_EXPIRE_YEARS,
      selectedCourse: {
        notification_id: null,
        has_student_id: 0,
        has_download: 0,
        has_register_after_test: 0,
        payment: 0,
        active_register: 0,
        active_login: 0,
        active: 0,
        random_questions: 0,
        show_function_name: 0,
        show_birthdate: 0,
        display_franchise_partner_name: 0,
      },
      isScorm: false,
      languages: LANGUAGES,
      rules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 2000000 ||
            "Image size should be less than 2 MB!"
          );
        },
      ],
      colorPickers: [
        { text: "Background Color Hex", db: "background_color_hex" },
        { text: "Button Color Hex", db: "button_color_hex" },
        { text: "Menu Background Color Hex", db: "menu_background_color_hex" },
        { text: "Menu Font Color Hex", db: "menu_font_color_hex" },
        { text: "Main Text Color Hex", db: "main_text_color_hex" },
        {
          text: "Mobile Menu Background Color Hex",
          db: "mobile_menu_background_color_hex",
        },
      ],
      switches: [
        { text: "Active", db: "active" },
        { text: "Active Login", db: "active_login" },
        { text: "Active Register", db: "active_register" },
        { text: "Has student ID", db: "has_student_id" },
        { text: "Has download", db: "has_download" },
        { text: "Has register after test", db: "has_register_after_test" },
        { text: "Payment", db: "payment" },
        { text: "Random Question", db: "random_questions" },
        { text: "Show function name", db: "show_function_name" },
        { text: "Show Birthdate", db: "show_birthdate" },
        {
          text: "Display franchise partner name",
          db: "display_franchise_partner_name",
        },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    const isScorm = _.get(to, "params.isScorm");
    const id = _.get(to, "params.id");

    if (id) {
      next((vm) => {
        vm.isScorm = isScorm;
      });
    } else {
      next({ name: "course-view" });
    }
  },

  async mounted() {
    const data = _.find(this.getCourseLookup, {
      id: Number(this.$route.params.id),
    });

    this.selectedCourse = this.isScorm
      ? { ...data, ...data.scorm }
      : _.get(data, "course");

    this.getNotifications.length
      ? this.getNotifications
      : await this.setNotifications();
  },

  computed: {
    ...mapGetters([
      "getCourseLookup",
      "getCertificates",
      "getNotifications",
      "getDomain",
    ]),

    getColorModel() {
      return (object) => _.get(this.selectedCourse, object);
    },

    notifications() {
      const selections = [];

      this.getNotifications.forEach((item) => {
        selections.push({
          label: item.key,
          value: item.id,
        });
      });

      return selections;
    },
  },

  methods: {
    ...mapActions(["editCourses", "editScorm", "setNotifications"]),

    setColor(object, value) {
      if (typeof value === "string") {
        _.set(this.selectedCourse, object, value);
      }
    },

    async edit() {
      try {
        if (
          !this.$refs.form.validate() ||
          (!this.selectedCourse.background_image && !this.isScorm)
        ) {
          this.$store.dispatch(
            "showSnackbar",
            "One or more fields are invalid."
          );
          return;
        }

        let formData = convertObjectToFormData(this.selectedCourse);

        this.isScorm
          ? await this.editScorm(formData)
          : await this.editCourses(formData);

        this.$store.dispatch("showSnackbar", "Course edit success.");
        this.$store.dispatch("setCourseLookup");
        this.$router.push({ name: "course-view" });
      } catch (error) {
        this.$store.dispatch("showSnackbar", "Something went wrong.");
      }
    },
  },
};
</script>
<style>
.color-picker-label {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0 !important;
}

label {
  overflow: inherit !important;
}

.label {
  font-size: 16px;
  padding-left: 8px;
}
</style>
